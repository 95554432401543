import axios from 'axios'
import { print } from 'graphql'

import { QUERY_REFRESH_TOKEN } from 'graphql/query'

import { decrypt } from './encrypt'

// const keyClientToken = 'ctObject'
const keyActiveToken = 'hcs'

const getGQLInternalURL = () => window.REACT_APP_GQL_INTERNAL_URL

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(`__${key}__`, JSON.stringify(value))
}

export const getFromLocalStorage = key => {
  return localStorage.getItem(`__${key}__`)
}

export const removeFromLocalStorage = key => {
  localStorage.removeItem(`__${key}__`)
}

const getXApiKey = () => decrypt(window.REACT_APP_XK)

export const getAccessToken = () => {
  return JSON.parse(getFromLocalStorage(keyActiveToken))
}

const getBasicKey = (i, s) => {
  return window.btoa(`${decrypt(i)}:${decrypt(s)}`)
}

const graphQLHeaders = () => {
  let accessToken = ''
  const token = getAccessToken()
  if (token !== null) {
    accessToken = token.access_token
  }
  return {
    Authorization: token === null
      ? `Basic ${getBasicKey(window.REACT_APP_CID, window.REACT_APP_CSC)}`
      : `Bearer ${accessToken}`,
    'Content-Type': 'application/json', // default content-type: application/json
    'x-api-key': getXApiKey()
  }
}

// const axiosInterceptor = () => {
//   let isRefreshing = false
//   let failedQueue = []
//   const subscribeToken = cb => {
//     failedQueue.push(cb)
//   }
//   const onRefreshed = token => {
//     failedQueue.map(cb => cb(token))
//   }
//   axios.interceptors.response.use(
//     (response) => {
//       return response
//     },
//     (error) => {
//       const { config, response: { status, data } } = error
//       const { errors } = data
//       const originalRequest = config
//       if (status !== 400 || errors[0].code !== 401) {
//         return Promise.reject(error)
//       } else {
//         const { refresh_token } = getAccessToken()
//         if (!isRefreshing) {
//           isRefreshing = true
//           axios.post(
//             getGQLInternalURL(),
//             {
//               query: print(QUERY_REFRESH_TOKEN),
//               variables: {}
//             },
//             {
//               headers: {
//                 ...graphQLHeaders(),
//                 token: `${refresh_token}`,
//                 scope: 'internal'
//               }
//             }
//           )
//             .then(({data}) => {
//             })
//             .catch(error => {
//             })
//         }
//         const retryOriginalRequest = new Promise((resolve, reject) => {
//           subscribeToken(newToken => {
//             originalRequest.headers['token'] = newToken
//             resolve(axios(originalRequest))
//           })
//         })
//         return retryOriginalRequest
//       }
//     }
//   )
// }


const axiosRequest = axios.create()

const onSuccess = (response) => {
  return response
}

const onError = async (error) => {
  const { refresh_token } = getAccessToken()
  const dataRequest = {
    queryMutation: QUERY_REFRESH_TOKEN,
    variables: {
      input: {
        grant_type: 'refresh_token',
        refresh_token: `${refresh_token}`
      }
    },
    headers: {
      Authorization: `Basic ${getBasicKey(window.REACT_APP_CID, window.REACT_APP_CSC)}`
    }
  }
  const { success, errors } = await GQLRequest(dataRequest)
  if (success !== null) {
    saveToLocalStorage('hcs', success)
  }
  if (errors !== null) {
    window.location.href = "/logout"
  }
  return error
  
}

axiosRequest.interceptors.response.use(
  function(response) {
    for (let prop in response.data.data) {
      if (response.data.data[prop]?.code === 401) {
        if (prop === 'AccountLogin') {
          return onSuccess(response)
        } 

        return onError()
      }

      return onSuccess(response)
    }
  }

)

/**
 * GQLRequest
 * @param {*} param0 
 * @returns 
 */
export const GQLRequest = async ({ queryMutation, variables, headers, formData }) => {
  let success =null
  let errors = null
  let payload = {}
  payload = {
    data: { query: print(queryMutation), variables: variables }
  }
  /**
   * formData for upload file
   */
  if (typeof formData !== 'undefined' && formData.length > 0) {
    const operations = JSON.stringify(
      {
        query: print(queryMutation),
        variables: variables
      }
    )
    let newFormData = new FormData()

    let mapData = {}
    formData.forEach((data, index) => {
      mapData[`${index}`]= [data.file !== ''  ? `variables.input.${data.name_input}` : '']
    })
    const map = JSON.stringify({ ...mapData })

    newFormData.append('operations', operations)
    newFormData.append('map', map)
    formData.forEach((data, index) => {
      newFormData.append(`${index}`, data.file)
    })
    payload = {
      data: newFormData
    }
  }

  await axiosRequest.post(
    getGQLInternalURL(),
    payload.data,
    { headers: { ...graphQLHeaders(), ...headers }}
  )
    .then( response => {
      errors = (typeof response.data.errors !== 'undefined') ? response.data.errors : null
      for (let prop in response.data.data) {
        if (response.data.data.hasOwnProperty(prop) && response.data.data[prop] !== null) {
          if (response.data.data[prop].data !== null) {
            success = response.data.data[prop].data
          } else {
            errors = {
              code: response.data.data[prop].code,
              message: response.data.data[prop].message
            }
          }
        }
        if (response.data.data.hasOwnProperty(prop) && response.data.data[prop] === null) {
          if (typeof response.data.errors === 'undefined') {
            errors = {
              code: 404,
              message: 'Data not Found'
            }
          }
        }
      }
    })
    .catch( error => {
      errors = typeof error.response !== 'undefined' ? error.response.data.errors[0] : error[0]
    })
  return { errors, success }
}



/**
 * fetchingData
 * @param {*} param0 
 */
export const fetchingData = async ({ params, dispatchAction }) => {
  dispatchAction({ type: 'FETCH_DATA_START' })
  const { success, errors } = await GQLRequest(params)
  if (success !== null) {
    dispatchAction({ type: 'FETCH_DATA_SUCCESS', payload: success })
  }
  if (errors !== null) {
    dispatchAction({ type: 'FETCH_DATA_ERROR', payload: errors })
  }
}
