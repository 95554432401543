import {
  SOUND_TYPE,
  SOUND_CHAT_NEW_NOTIFICATION,
  SOUND_CHAT_UNREAD_NOTIFICATION
} from 'constants/sound'


/**
 * notificationSound
 * @returns 
 */
export const notificationSound = (type) => {
  const tone = type === SOUND_TYPE.UNREAD_CHAT
    ? SOUND_CHAT_UNREAD_NOTIFICATION
    : SOUND_CHAT_NEW_NOTIFICATION
  return new Promise((resolve, reject) => {
    const vol = 100
    try {
      const sound = new Audio(tone)
      sound.volume = vol / 100
      sound.onended = () => {
        resolve()
      }
      sound.play()
    } catch (error) {
      reject(error)
    }
  })
}

/**
 * notificationDesktop
 * @param {*} param0 
 */
export const notificationDesktop = ({ title, body, isClickable }) => {
  const img = 'https://s3-ap-southeast-1.amazonaws.com/bobobox-assets/assets/favicon/favicon-96x96.png'
  // call notification sound
  notificationSound(SOUND_TYPE.NEW_CHAT)
  const notif = new Notification(
    title,
    {
      icon: img,
      body: body,
      vibrate: [200, 100, 200]
    }
  )
  if (isClickable) {
    notif.onclick = function(e) {
      e.preventDefault()
      window.focus()
    }
  }
}

