export const ICON_BBX_CIRCLE = `https://storage.googleapis.com/cf-web-assets/hcs/icons/bobobox-logo.svg`
export const ICON_MENU = `https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-icon.svg`
export const ICON_MENU_PUSH = `https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-push-icon.svg`
export const ICON_DASHBOARD = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-dashboard-icon.svg'
export const ICON_RESERVATION_STAY = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-rsv-stay-icon.svg'
export const ICON_STAY = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-stay-icon.svg'
export const ICON_OCC_RATE = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-occ-rate-icon.svg'
export const ICON_ROOM = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-room-info-icon.svg'
export const ICON_RATE = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-room-rate-icon.svg'
export const ICON_NIGHT_AUDIT = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/menu-night-audit-icon.svg'
export const ICON_CHECKOUT = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/checkout-icon.svg'

export const ICON_PROFILE = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/profile-icon.svg'
export const ICON_CHAT = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/chat-icon.svg'

export const ICON_MORE_MENU = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/more-icon.svg'

export const ICON_DOOR_LOCK = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/iot-door-lock.svg'
export const ICON_MOOD_LAMP = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/iot-mood-lamp.svg'
export const ICON_SMART_GLASS = 'https://storage.googleapis.com/cf-web-assets/hcs/icons/iot-smart-glass.svg'

export const ICON_CHECK = `https://storage.googleapis.com/cf-web-assets/hcs/icons/check-icon.svg`
export const ICON_REFRESH = `https://storage.googleapis.com/cf-web-assets/hcs/icons/refresh-icon.svg`

export const ICON_CLOUD_UPLOAD = `https://storage.googleapis.com/cf-web-assets/hcs/icons/cloud-upload.svg`
export const ICON_CLOUD_UPLOAD_WHITE = `https://storage.googleapis.com/cf-web-assets/hcs/icons/cloud-upload-white.svg`

export const ICON_WARNING = `https://storage.googleapis.com/cf-web-assets/hcs/icons/warning-icon.svg`

export const QR_EMPTY = `https://storage.googleapis.com/cf-web-assets/hcs/qr-empty.svg`

export const ARROW = {
  left: 'https://storage.googleapis.com/cf-web-assets/hcs/icons/arrow-left.svg',
  right: 'https://storage.googleapis.com/cf-web-assets/hcs/icons/arrow-right.svg'
}

export const BUTTON_DOOR_LOCK = `https://storage.googleapis.com/cf-web-assets/hcs/button-lock-door.svg`
export const BUTTON_DOOR_UNLOCK = `https://storage.googleapis.com/cf-web-assets/hcs/button-unlock-door.svg`