import { AuditOutlined, TagOutlined, ScheduleOutlined, AppstoreOutlined, RiseOutlined, HomeOutlined } from '@ant-design/icons'
import React, { lazy } from 'react'

import {
  ICON_DASHBOARD, ICON_RESERVATION_STAY, ICON_OCC_RATE,
  ICON_ROOM, ICON_RATE, ICON_NIGHT_AUDIT, ICON_STAY, ICON_CHECKOUT
} from 'constants/image'
import { 
  PAGE_DASHBOARD, PAGE_NIGHT_AUDIT, PAGE_OCCUPANCY_RATE, PAGE_RESERVATION_STAY, 
  PAGE_ROOM_RATE, PAGE_ROOM_UNIT, PAGE_LOGIN, PAGE_LOGOUT, PAGE_STAY, PAGE_CHECKOUT 
} from 'constants/router'


const PageDashboard = lazy(() => import('pages/dashboard/index'))
const PageReservationStay = lazy(() => import('pages/reservation-stay/index'))
const PageStay = lazy(() => import('pages/stay/index'))
const PageStayDetail = lazy(() => import('pages/stay/detail/index'))
const PageOccupancyRate = lazy(() => import('pages/occupancy-rate/index'))
const PageRoomUnit = lazy(() => import('pages/room-unit/index'))
const PageRoomRate = lazy(() => import('pages/room-rate/index'))
const PageNightAudit = lazy(() => import('pages/night-audit/index'))
const PageCheckout = lazy(() => import('pages/checkout/index')) 

const PageLogin = lazy(() => import('pages/auth/login/index'))
const PageLogout = lazy(() => import('pages/auth/logout/index'))

export const authRouter = [
  {
    key: 1,
    path: PAGE_LOGIN, 
    component: PageLogin, 
    exact: false
  },
  {
    key: 2, 
    path: PAGE_LOGOUT, 
    component: PageLogout, 
    exact: false
  }
]

export const mainRouter = [
  { 
    key: '1',
    groupId: '1',
    path: PAGE_DASHBOARD, 
    component: PageDashboard, 
    exact: true,
    label: 'Dashboard',
    icon: <HomeOutlined />,
    imgIcon: ICON_DASHBOARD,
    mainMenu: true,
  },
  { 
    key: '2',
    groupId: '2',
    path: PAGE_RESERVATION_STAY,
    component: PageReservationStay,
    exact: true,
    label: 'Reservation',
    icon: <ScheduleOutlined />,
    imgIcon: ICON_RESERVATION_STAY,
    mainMenu: true,
  },
  { 
    key: '3',
    groupId: '3',
    path: PAGE_STAY,
    component: PageStay, 
    exact: true,
    label: 'Stay',
    icon: <ScheduleOutlined />,
    imgIcon: ICON_STAY,
    mainMenu: true,
  },
  { 
    key: '4',
    groupId: '4',
    path: PAGE_ROOM_UNIT,
    component: PageRoomUnit,
    exact: true,
    label: 'Room Information',
    icon: <AppstoreOutlined />,
    imgIcon: ICON_ROOM,
    mainMenu: true,
  },
  { 
    key: '5',
    groupId: '5',
    path: PAGE_CHECKOUT,
    component: PageCheckout, 
    exact: true,
    label: 'Check-out Info',
    imgIcon: ICON_CHECKOUT,
    mainMenu: true,
  },
  { 
    key: '6',
    groupId: '6',
    path: PAGE_OCCUPANCY_RATE,
    component: PageOccupancyRate, 
    exact: true,
    label: 'Occupancy Rate',
    icon: <RiseOutlined />,
    imgIcon: ICON_OCC_RATE,
    mainMenu: true,
  },
  
  { 
    key: '7',
    groupId: '7',
    path: PAGE_ROOM_RATE,
    component: PageRoomRate,
    exact: true,
    label: 'Rate Information',
    icon: <TagOutlined />,
    imgIcon: ICON_RATE,
    mainMenu: true,
  },
  { 
    key: '8',
    groupId: '8',
    path: PAGE_NIGHT_AUDIT,
    component: PageNightAudit,
    exact: true,
    label: 'Night Audit',
    icon: <AuditOutlined />,
    imgIcon: ICON_NIGHT_AUDIT,
    mainMenu: true,
  },
  {
    key: '9',
    groupId: '3',
    path: `${PAGE_STAY}/:id`,
    component: PageStayDetail,
    exact: true,
    label: 'Stay Detail ',
    icon: <AuditOutlined />,
    imgIcon: ICON_NIGHT_AUDIT,
    mainMenu: false
  }
]