import React, { createContext, useState } from 'react'

const inisialState = {
  account: null,
  alert: {
    success: {
      data: null,
      message: null
    },
    error: {
      data: null,
      message: null
    }
  },
  filter: {
    room: {
      activeStatus: null
    },
    nightAudit: {
      status: null
    }
  },
  currentMenuKey: null,
  chatNotifCount: 0,
  showModal: {
    chat: false
  }
}

export const AppContext = createContext()

export const AppContextProvider = props => {
  const [state, setState] = useState(inisialState)

  return (
    <AppContext.Provider value={{
      state: state,
      setSuccess: (message) => {
        setState({
          ...state,
          alert: {
            ...state.alert,
            success: {
              ...state.alert.success,
              message: message
            }
          }
        })
      },
      setError: (message) => {
        setState({
          ...state,
          alert: {
            ...state.alert,
            error: {
              ...state.alert.error,
              message: message
            }
          }
        })
      },
      setAccountData: (data) => {
        setState({
          ...state,
          account: data
        })
      },
      clearAccountData: () => {
        setState({
          ...state,
          account: null
        })
      },
      setFilterRoomFromDashboard: (room, newMenuKey) => {
        setState({
          ...state,
          filter: {
            ...state.filter,
            room: {
              ...state.filter.room,
              activeStatus: room
            }
          },
          currentMenuKey: newMenuKey.toString()
        })
      },
      setFilterNightAuditStatus: (status, newMenuKey) => {
        setState({
          ...state,
          filter: {
            ...state.filter,
            nightAudit: {
              ...state.filter.nightAudit,
              status: status
            }
          },
          currentMenuKey: newMenuKey.toString()
        })
      },
      setFilterRoom: (data) => {
        setState({
          ...state,
          filter: {
            ...state.filter,
            room: {
              ...state.filter.room,
              activeStatus: data
            }
          }
        })
      },
      setCurrentMenuKey: newKey => {
        setState({
          ...state,
          currentMenuKey: newKey.toString()
        })
      },
      setChatNotifCount: (count) => {
        setState({
          ...state,
          chatNotifCount: count
        })
      },
      increaseChatNotifCount: () => {
        setState({
          ...state,
          chatNotifCount: state.chatNotifCount + 1
        })
      },
      decreaseChatNotifCount: () => {
        setState({
          ...state,
          chatNotifCount: state.chatNotifCount - 1
        })
      },
      showHideModalChat: () => {
        setState({
          ...state,
          showModal: {
            ...state.showModal,
            chat: !state.showModal.chat
          }
        })
      }
    }}>
      {props.children}
    </AppContext.Provider>
  )
}