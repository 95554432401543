import { Modal, notification } from 'antd'
import React from 'react'

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config);

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        navigator.serviceWorker.ready.then(() => {
          // console.log(
          //   'This web app is being served cache-first by a service ' +
          //     'worker. To learn more, visit https://bit.ly/CRA-PWA'
          // );
        });
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config);
      }
    });
  }
}

/**
 * registerValidSW
 * @param {*} swUrl 
 * @param {*} config 
 */
function registerValidSW(swUrl, config) {
  navigator.serviceWorker.register(swUrl)
    .then( registration => {
      registration.update();
      setInterval(() => {
        registration.update()
        if (window.REACT_APP_ENV === 'staging') {
          // for debugging
          // eslint-disable-next-line
          console.debug('Checked for update...')
        }
      }, (1000 * 60) * window.REACT_APP_TIME_UPDATE)

      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker === null) {
          return;
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              if (window.REACT_APP_ENV === 'staging') {
                // for debugging
                const newContentMessage = `New content is available and will be used when all tabs for this page are closed.`
                // eslint-disable-next-line
                console.log(newContentMessage)
              }
              // show modal info when update content available
              Modal.info({
                title: 'HCS Update Available!',
                centered: true,
                maskClosable: false,
                closable: false,
                content: (
                  <div>
                    To update, close all windows and reopen.
                  </div>
                ),
                onOk() {
                  window.close()
                },
                okText: 'Update'
              })

              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // for debugging
              // content is cached for offline use
              // eslint-disable-next-line
              console.log('Content is cached for offline use')
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch(error => {
      if (window.REACT_APP_ENV === 'staging') {
        // error debugging
        // eslint-disable-next-line
        console.error('Error during service worker registration', error)
      }
    })
}

function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' },
  })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType !== null && contentType.indexOf('javascript') === -1)
      ) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      // console.error(
      //   'No internet connection found. App is running in offline mode.'
      // );
      notification.warning({
        message: 'Internet Connection Issue',
        description: 'No internet connection found. Check your internet connection.'
      })
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        if (window.REACT_APP_ENV === 'staging') {
          // error debugging
          // eslint-disable-next-line  
          console.error(error.message);
        }
      });
  }
}
