// import { Partytown } from '@builder.io/partytown/react'
import styled from '@emotion/styled'
import { Button } from 'antd';
import React, { useEffect, Suspense } from 'react'
import { useClearCache } from 'react-clear-cache';
import { Switch, Route, BrowserRouter, useLocation, Redirect } from 'react-router-dom'

import RibbonLabel from 'components/ui/ribbon';
import Spinner from 'components/ui/spinner/index'
import { AppContextProvider } from 'context/app-context'
import { authRouter } from 'router'

import Main from './components/layout/main'
import { PAGE_LOGIN } from './constants/router'
import { isAuth } from './utils/auth'

import './App.css'

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return null
}

const MainRoute = ( { component: Component, ...rest } ) => {
  return (
    <Route {...rest} render={ props => (
      isAuth()
        ? <Component {...props} />
        : <Redirect to={PAGE_LOGIN} />
    )} />
  )
}

const WrapperButtonUpdate = styled.div`
  background: #dadada;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
`

/**
 * App component
 * @returns 
 */
function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  return (
    <div className="App">
      {
        /**
         * add ribbon label if testing / staging environment
         */
        window.REACT_APP_ENV !== 'production' && (
          <RibbonLabel label="Testing"/>
        )
      }
      {
        !isLatestVersion && (
          <WrapperButtonUpdate>
            <Button type="primary" onClick={() => emptyCacheStorage()}>
              Update new version
            </Button>
          </WrapperButtonUpdate>
        )
      }
      <AppContextProvider>
        {/* <Partytown debug={process.env.NODE_ENV !== 'production'} forward={['mixpanel.track']} /> */}
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ScrollToTop />
          <Switch>
            <Suspense fallback={<Spinner className="main-spinner-suspense"/>}>
              {
                /**
                 * auth router (ex: login, logout)
                 */
                authRouter.map( route => {
                  return <Route path={route.path} exact={route.exact} component={route.component} key={route.key} />
                })
              }
              <MainRoute path="/" component={Main}/>
            </Suspense>
          </Switch>
        </BrowserRouter>
      </AppContextProvider>
    </div>
  );
}

export default App;
