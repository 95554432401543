
export const SOUND_TYPE = {
  UNREAD_CHAT: 'UNREAD_CHAT',
  NEW_CHAT: 'NEW_CHAT'
}

export const SOUND_CHAT_NEW_NOTIFICATION =
  'https://storage.googleapis.com/cf-web-assets/hcs/sounds/sound-chat-new-notification.wav'
export const SOUND_CHAT_UNREAD_NOTIFICATION =
  'https://storage.googleapis.com/cf-web-assets/hcs/sounds/sound-chat-unread-notification.mp3'
