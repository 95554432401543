import styled from '@emotion/styled'
import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'

import { ICON_BBX_CIRCLE, ICON_MENU_PUSH, ICON_MORE_MENU } from 'constants/image'
import { AppContext } from 'context/app-context'
import { mainRouter } from 'router'

const Wrapper = styled.div`
  z-index: 10;
  .masking {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #000000;
    transition: all 0.3s;
    &.show {
      opacity: 0.5;
      cursor: pointer;
      pointer-events: auto;
    }
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
  }  
`

const SidebarWrapper = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }
  background: #ffffff;
  width: 84px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  transition: all 0.2s;
  &.expanded {
    width: 240px;
    .menu-sidebar-item {
      &.menu-burger {
        justify-content: flex-end;
        .logo {
          opacity: 1;
        }
        .menu-icon {
          transform: rotate(-180deg);
        }
      }
    }
  }
  .menu-sidebar-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    z-index: 2;
  }
  a {
    padding: 6px 20px;
    &:hover {
      .menu-sidebar-item {
        background-color: #2DB6B6;
        .menu-icon {
          filter: brightness(150%) contrast(150%);
        }
        .menu-sidebar-item-label {
          color: #ffffff;
        }
      }
    }
  }
  .menu-sidebar-item {
    cursor: pointer;
    // margin: 12px;
    padding: 12px;
    border-radius: 6px;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    overflow: hidden;
    .menu-icon {
      width: 20px;
      height: 20px;
      margin: 0;
      padding: 0;
    }
    &.menu-burger {
      position: relative;
      margin: 12px 20px;
      background-color: #ffffff;
      .logo {
        position: absolute;
        left: 7px;
        transition: all 0.2s;
        z-index: 1;
        overflow: hidden;
        white-space: nowrap;
        opacity: 0;
        display: flex;
        align-items: center;
        span {
          font-size: 18px;
          font-weight: 700;
          color: #29a4a4;
          margin-left: 12px;
        }
      }
      .menu-icon {
        z-index: 2;
        transition: all .3s;
      }
      &:hover {
        background-color: #fafafa;
        .menu-icon {
          filter: none;
        }
      }
      
    }
    &:hover,
    &.active {
      background-color: #2DB6B6;
      .menu-icon {
        filter: brightness(150%) contrast(150%);
      }
      .menu-sidebar-item-label {
        color: #ffffff;
      }
    }
    .menu-sidebar-item-label {
      font-size: 12px;
      margin-left: 14px;
      white-space: nowrap;
      color: #aeaeae;
      font-weight: 600;
      &.show {
        width: 200px;
      }
      &.hide {
        width: 0;
      }
    }
  }
`

const BottomMenuWrapper = styled.div`
  display: block;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-top: 1px solid #dfdfdf;
  z-index: 10;
  @media only screen and (min-width: 768px) {
    display: none;
  }
  .menu-bottom-wrapper{
    display: flex;
    height: 56px;
    justify-content: space-around;
    align-items: center;
    z-index: 2;
    position: relative;
    background: white;
    a {
      padding: 6px;
    }
  }
  
  .menu-bottom-item {
    cursor: pointer;
    padding: 12px;
    border-radius: 6px;
    transition: all 0.4s;
    display: flex;
    align-items: center;
    overflow: hidden;
    .menu-icon {
      width: 20px;
      height: 20px;
      margin: 0;
      padding: 0;
    }
    &:hover,
    &.active {
      background-color: #2DB6B6;
      .menu-icon {
        filter: brightness(150%) contrast(150%);
      }
      .menu-bottom-item-label {
        color: #ffffff;
      }
    }
    .menu-bottom-item-label {
      font-size: 12px;
      margin-left: 14px;
      white-space: nowrap;
      color: #858585;
      font-weight: 600;
    }
  }
  .menu-more-wrapper {
    z-index: 2;
  }
  .more-menu {
    position: absolute;
    display: flex;
    bottom: -200px;
    background: white;
    flex-direction: column;
    width: 100%;
    padding: 36px 12px 12px 12px;
    transition: all .2s ease;
    z-index: 0;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    &.expanded  {
      bottom: 56px;
    }
  }
`

const logoLabel = `HCS`
const logoBbx = ICON_BBX_CIRCLE
const iconMenuPush = ICON_MENU_PUSH
const iconMoreMenu = ICON_MORE_MENU


/**
 * Sidebar
 * sidebar components
 * @returns 
 */
function Sidebar({ collapse, setCollapse }) {
  const appContext = useContext(AppContext)

  useEffect(() => {
    let currentMenu = mainRouter.filter(menu => {
      const windowPath = window.location.pathname.split('/')
      const mPath = menu.path.split('/')
      return windowPath[1] === mPath[1] && menu.mainMenu
    })
    if (currentMenu.length > 0 && appContext.state.currentMenuKey === null) {
      appContext.setCurrentMenuKey(currentMenu[0].groupId)
    }
  }, [appContext])

  const setActiveMenu = (groupId) => {
    appContext.setCurrentMenuKey(groupId)
  }

  const filterMenuByRoles = (mainRouter, rolesId) => {
    const router = [...mainRouter]
    if (parseInt(rolesId) === 13) {
      return router
        .filter( dataItem => {
          return dataItem.key !== '2' && dataItem.key !== '6'
        })
    } else {
      return router
    }
  }

  return (
    <Wrapper>
      <div
        className={`masking ${collapse ? 'hide': 'show'}`}
        onClick={() => setCollapse(!collapse)}
      />
      <SidebarWrapper className={`${!collapse ? 'expanded' : ''}`}>
        <div className="menu-sidebar-wrapper">
          <div className="menu-burger menu-sidebar-item"  onClick={() => setCollapse(!collapse)}>
            <div className="logo">
              <img src={logoBbx} className="logo-bbx" alt="hcs - bobobox" />
              <span>{logoLabel}</span>
            </div>
            <img src={iconMenuPush} className="menu-icon" alt="menu" />
          </div>
        {
          typeof appContext.state?.account?.roles[0] !== 'undefined' && (
            filterMenuByRoles(mainRouter, appContext.state.account.roles[0])
              .filter(data => data.mainMenu) // filter only main menu
              .map(route => {
                return (
                  <Link 
                    key={route.key}
                    to={route.path}
                    onClick={() => setActiveMenu(route.groupId)}
                  >
                    <div
                      className={`menu-sidebar-item ${parseInt(appContext.state.currentMenuKey) === parseInt(route.groupId) ? 'active' : ''}`}
                    >
                        <img src={route.imgIcon} className="menu-icon" alt={route.label} />
                        <span className={`menu-sidebar-item-label ${!collapse ? 'show' : 'hide'}`}>
                          {route.label}
                        </span>
                    </div>
                  </Link>
                )
              })
          )
        }
        </div>
      </SidebarWrapper>
        
      <BottomMenuWrapper>
        <div style={{ position: 'relative'}}>
          {
            typeof appContext.state?.account?.roles[0] !== 'undefined' && (
              <div className={`more-menu ${!collapse ? 'expanded' : ''}`}>
                {
                  filterMenuByRoles(mainRouter, appContext.state.account.roles[0])
                    .filter( data => data.mainMenu ) // filter only mainMenu 
                    .slice(4)
                    .map( route => {
                      return (
                        <Link
                          key={route.key}
                          to={route.path}
                          onClick={() => setActiveMenu(route.key)}
                        >
                          <div className={`menu-bottom-item ${parseInt(appContext.state.currentMenuKey) === parseInt(route.key) ? 'active' : ''}`}>
                            <img src={route.imgIcon} className="menu-icon" alt={route.label} />
                            <span className={`menu-bottom-item-label ${!collapse ? 'show' : 'hide'}`}>
                              {route.label}
                            </span>
                          </div>
                        </Link>
                      )
                    })
                }
              </div>
            )
          }
          <div className="menu-bottom-wrapper">
            {
              typeof appContext.state?.account?.roles[0] !== 'undefined' && (
                <>
                  {
                    filterMenuByRoles(mainRouter, appContext.state.account.roles[0])
                      .filter(data => data.mainMenu) // filter only mainmenu
                      .slice(0, 4)
                      .map( route => {
                        return (
                          <Link
                            key={route.key}
                            to={route.path}
                            onClick={() => setActiveMenu(route.key)}
                          >
                            <div className={`menu-bottom-item ${parseInt(appContext.state.currentMenuKey) === parseInt(route.key) ? 'active' : ''}`}>
                              <img src={route.imgIcon} className="menu-icon" alt={route.label} />
                              
                            </div>
                          </Link>
                        )
                      })
                  }
                  {
                    filterMenuByRoles(mainRouter, appContext.state.account.roles[0]).length > 4 && (
                      <>
                        <div className={`menu-bottom-item`} onClick={() => setCollapse(!collapse)}>
                          <img src={iconMoreMenu} className="menu-icon" alt={`see more`} />
                        </div>
                      </>
                    )
                  }
                </>
                
              )
            }
          
          </div>
        </div>
      </BottomMenuWrapper>
    </Wrapper>
    
  )
}

export default Sidebar
