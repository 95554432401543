
export const PAGE_DASHBOARD = '/'
export const PAGE_RESERVATION_STAY = '/reservation-stay'
export const PAGE_STAY = '/stay'
export const PAGE_OCCUPANCY_RATE = '/occupancy-rate'
export const PAGE_ROOM_UNIT = '/room-unit'
export const PAGE_ROOM_RATE = '/room-rate'
export const PAGE_NIGHT_AUDIT = '/night-audit'
export const PAGE_LOGIN = '/login'
export const PAGE_LOGOUT = '/logout'
export const PAGE_CHECKOUT = '/checkout'