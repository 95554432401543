import styled from '@emotion/styled'
import React from 'react'

const WrapperRibbon = styled.div`
  position: fixed;
  background: #ec920c;
  transform: rotate(-45deg);
  width: 250px;
  z-index: 100;
  text-align: center;
  color: white;
  line-height: 30px;
  top: 20px;
  left: -90px;
  font-weight: 700;
  pointer-events: none;
`

const RibbonLabel = ({ label }) => {
  return (
    <WrapperRibbon className="ribbon-label">
      {label}
    </WrapperRibbon>
  )
}

export default RibbonLabel

