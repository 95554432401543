import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import mixpanel from 'mixpanel-browser';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

mixpanel.init(window.REACT_APP_MIXPANEL_ID, { debug: process.env.NODE_ENV !== 'production'})

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: window.REACT_APP_SENTRY,
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // reference: https://docs.sentry.io/platforms/javascript/guides/react/configuration/sampling/
    tracesSampleRate: 1.0,
    environment: window.REACT_APP_ENV
  });  
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// unregister service worker
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


