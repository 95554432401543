import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

/**
 * Spinner
 * spin component with custom loading icon
 * @param {*} param0 
 * @returns 
 */
const Spinner = ({ children, ...rest }) => (
  <Spin indicator={antIcon} {...rest}>{children}</Spin>
)

export default Spinner
