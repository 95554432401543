import { getFromLocalStorage  } from "./http"

const keyActiveToken = 'hcs'

/**
 * isAuth
 * checking access token in localStorage
 * @returns 
 */
export const isAuth = () => {
  let isAuth = false
  if (getFromLocalStorage(keyActiveToken) !== null) {
    isAuth = true
  }
  return isAuth
}