import * as aesjs from 'aes-js';

const kl = [
  3, 1, 2, 6, 12, 5, 7, 13, 16, 11, 15, 10, 4, 14, 9, 8, 32, 31, 30, 21, 22, 23,
  26, 25, 24, 20, 27, 28, 29, 17, 19, 18,
];

/**
 * encrypt
 * @param str
 * @returns
 */
export const encrypt = (str) => {
  const text = str;
  const textBytes = aesjs.utils.utf8.toBytes(text);
  const aesCtr = new aesjs.ModeOfOperation.ctr(kl, new aesjs.Counter(8));
  const encryptedFromBytes = aesCtr.encrypt(textBytes);
  return aesjs.utils.hex.fromBytes(encryptedFromBytes);
};

/**
 * decrypt
 * @param str
 * @returns
 */
export const decrypt = (str) => {
  const text = str;
  const encryptedToBytes = aesjs.utils.hex.toBytes(text);
  const aesCtr = new aesjs.ModeOfOperation.ctr(kl, new aesjs.Counter(8));
  const decryptedBytes = aesCtr.decrypt(encryptedToBytes);
  return aesjs.utils.utf8.fromBytes(decryptedBytes);
};
