import { MessageOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Layout, message, Typography } from 'antd'
import React, { useState, useReducer, useEffect, useContext } from 'react'
import { Route, useHistory } from 'react-router-dom'

import { PAGE_LOGOUT } from 'constants/router'
import { SOUND_TYPE } from 'constants/sound'
import { AppContext } from 'context/app-context'
import { QUERY_ACCOUNT_DETAIL } from 'graphql/query'
import { mainRouter } from 'router'
import { isAuth } from 'utils/auth'
import { GQLRequest } from 'utils/http'
import { notificationDesktop, notificationSound } from 'utils/notification'
import { defaultStateDataAPI, fetchDataAPIReducer } from 'utils/reducer'

import Sidebar from './sidebar'

const { Text } = Typography

const ButtonCloseChat = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  right: 0;
  top: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  cursor: pointer;
`

const keyMessageNotifChat = 'messageNotifChat'
const INTERVAL_SOUND_UNREAD_CHAT = 5000 // 5s

const now = Date.now()

/**
 * Main Component
 * Main Layout component
 */
function Main() {
  const history = useHistory()
  const [collapse, setCollapse] = useState(true)
  const [accountDetail, setAccountDetail] = useReducer(fetchDataAPIReducer, defaultStateDataAPI)
  const appContext = useContext(AppContext)

  useEffect(() => {
    if (isAuth()) {
      const fetchAccountDetail = async () => {
        setAccountDetail({ type: 'FETCH_DATA_START' })
        const dataRequest = {
          queryMutation: QUERY_ACCOUNT_DETAIL,
          headers: {},
          variables: {}
        }
        const { success, errors } =  await GQLRequest(dataRequest)
        if (success !== null) {
          setAccountDetail({ type: 'FETCH_DATA_SUCCESS', payload: success })
          appContext.setAccountData(success)
        }
        if (errors !== null) {
          setAccountDetail({ type: 'FETCH_DATA_ERROR', payload: errors })
        }
      }
      if (!accountDetail.loading && !accountDetail.isLoaded) {
        fetchAccountDetail()
      }
    }
  }, [accountDetail.loading, accountDetail.isLoaded, accountDetail.data, appContext])

  useEffect(() => {
    if (accountDetail.error !== null) {
      history.push({ pathname: PAGE_LOGOUT })
    }
  }, [accountDetail.error, history])
  
  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission()
    }
    
    if (appContext.state.account !== null) {
      const iframeChat = document.getElementById('iframe-chat')
      const dataHotel = JSON.stringify({
        channel: 'iframe-chat',
        data: 'dataHotel',
        content: {
          hotelId: appContext.state.account.hotel_id 
        }
      })
      iframeChat.contentWindow.postMessage(dataHotel, window.REACT_APP_CHAT_QISCUS_V2)
    }
    
    const handlerChatNotif = function(e) {
      if (e.origin === window.REACT_APP_CHAT_QISCUS_V2) {
        const dataParse = JSON.parse(e.data)
        if (dataParse.channel === 'iframe-chat') {
          // checking newMessages message event
          if (dataParse.data === 'newMessages') {
            // play notification for new chat type 
            // if notification browser !== granted
            if (Notification.permission !== "granted") {
              notificationSound(SOUND_TYPE.NEW_CHAT)
            }
            message.open({
              content: <Text strong>{`You get a new message!`}</Text>,
              icon: <MessageOutlined />,
              key: keyMessageNotifChat,
              onClick: () => {
                if (!appContext.state.showModal.chat) {
                  appContext.showHideModalChat()
                }
              }
            })
            appContext.increaseChatNotifCount()
            // show notification to browser notification
            notificationDesktop({
              title: `HCS - New Message`,
              body: `You get a new message!`,
              isClickable: true
            })
          }
          // checking notifCount message event
          if (dataParse.data === 'notifCount') {
            appContext.setChatNotifCount(dataParse.content.notifCount)
          }
        }
      }
    }
    window.addEventListener('message', handlerChatNotif)
    return () => window.removeEventListener('message', handlerChatNotif)
  }, [appContext])

  useEffect(() => {
    // check if chat notif count > 0
    if (appContext.state.chatNotifCount > 0) {
      const setIntervalSound = setInterval(() => {
        // call sound of notification with type unread
        notificationSound(SOUND_TYPE.UNREAD_CHAT)
      }, INTERVAL_SOUND_UNREAD_CHAT)
      return () => clearInterval(setIntervalSound)
    }
  }, [appContext])
  
  return (
    <div id="main-layout" className="main-layout">
      <Layout className="main-layout-inner">
        <Layout className="main-layout-wrapper" hasSider={false}>
          {
            mainRouter.map( (route, index) =>
              <Route 
                path={route.path}
                component={route.component}
                exact={route.exact}
                key={index}
              />
            )
          }
          <div
            id="iframe-chat-wrapper"
            style={{
              position: 'fixed',
              height: `568px`,
              width: `360px`,
              bottom: appContext.state.showModal.chat ? `0` : `-568px`,
              right: 0,
              zIndex: 1051,
              transition: `bottom 0.4s`
            }}
          >
            <iframe
              src={`${window.REACT_APP_CHAT_QISCUS_V2}?v=${now}`}
              id="iframe-chat"
              style={{
                height: `100%`,
                border: `none`,
                width: `360px`
              }}
              title="iframe-chat"
            />
            <ButtonCloseChat
              onClick={() => appContext.showHideModalChat()}
            >
              &times;
            </ButtonCloseChat>
          </div>
        </Layout>
        <Sidebar collapse={collapse} setCollapse={setCollapse}/>
        {/* <MainSidebar collapsed={collapse} setCollapse={setCollapse}/> */}
      </Layout>
    </div>
  )
}

export default Main